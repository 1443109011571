import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "home-part" }
const _hoisted_2 = { class: "tab-content container py-2" }
const _hoisted_3 = {
  id: "home-part-4-tab-pane-1",
  class: "tab-pane fade show active"
}
const _hoisted_4 = { class: "row row-cols-1 row-cols-md-3 g-3" }
const _hoisted_5 = { class: "item col" }
const _hoisted_6 = { class: "card h-100" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "card-img-bottom border-0" }
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = {
  id: "home-part-4-tab-pane-2",
  class: "tab-pane fade"
}
const _hoisted_13 = { class: "row row-cols-1 row-cols-md-3 g-3" }
const _hoisted_14 = { class: "item col" }
const _hoisted_15 = { class: "card h-100 border-0" }
const _hoisted_16 = { class: "card-body" }
const _hoisted_17 = { class: "title" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "card-img-bottom border-0" }
const _hoisted_20 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartTitle = _resolveComponent("PartTitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PartTitle, { src: "/img/home/part-4/title.png" }),
    _cache[1] || (_cache[1] = _createElementVNode("ul", { class: "nav nav-tabs" }, [
      _createElementVNode("li", { class: "nav-item" }, [
        _createElementVNode("a", {
          href: "#home-part-4-tab-pane-1",
          "data-bs-toggle": "tab",
          role: "tab",
          class: "nav-link active"
        }, "俱乐部想要开展定向运动？")
      ]),
      _createElementVNode("li", { class: "nav-item" }, [
        _createElementVNode("a", {
          href: "#home-part-4-tab-pane-2",
          "data-bs-toggle": "tab",
          role: "tab",
          class: "nav-link"
        }, "营地/学校想要开展定向运动？")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items1, (item) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1),
                  _createElementVNode("div", {
                    innerHTML: item.content
                  }, null, 8, _hoisted_9)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("img", {
                    src: item.img.src,
                    alt: item.img.alt
                  }, null, 8, _hoisted_11),
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "arrow-down" }, null, -1))
                ])
              ])
            ]))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items2, (item) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, _toDisplayString(item.title), 1),
                  _createElementVNode("div", {
                    innerHTML: item.content
                  }, null, 8, _hoisted_18)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("img", {
                    src: item.img.src,
                    alt: item.img.alt
                  }, null, 8, _hoisted_20)
                ])
              ])
            ]))
          }), 256))
        ])
      ])
    ])
  ]))
}