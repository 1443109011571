import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "home-part" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "part-8-title" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "type" }
const _hoisted_6 = {
  key: 0,
  "data-bs-toggle": "modal",
  "data-bs-target": "#exampleModal",
  type: "button",
  class: "download-link"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "desc" }
const _hoisted_9 = { class: "row row-cols-2 row-cols-lg-4 gy-3 my-3" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "phone-item" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "phone-item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartTitle = _resolveComponent("PartTitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PartTitle, { src: "/img/home/part-8/title.png" }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["part-item", item.class])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.title.name), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.title.type), 1),
            (_ctx.isWeiXin)
              ? (_openBlock(), _createElementBlock("button", _hoisted_6, "下载App"))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: ($event: any) => (_ctx.download(item.title.isoUrl, item.title.androidUrl)),
                  type: "button",
                  class: "download-link"
                }, "下载App", 8, _hoisted_7))
          ]),
          _createElementVNode("div", _hoisted_8, _toDisplayString(item.desc), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (list) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list, (item) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("img", {
                      src: item.src,
                      class: "phone-item-img"
                    }, null, 8, _hoisted_12),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(item.label), 1)
                  ])
                ]))
              }), 256))
            ]))
          }), 256))
        ])
      ], 2))
    }), 256)),
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      class: "modal fade",
      id: "exampleModal",
      tabindex: "-1",
      "aria-labelledby": "exampleModalLabel",
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", { class: "modal-dialog" }, [
        _createElementVNode("div", { class: "modal-content" }, [
          _createElementVNode("div", { class: "modal-body" }, "请在浏览器中打开")
        ])
      ])
    ], -1))
  ]))
}