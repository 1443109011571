import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "carousel-indicators-container container" }
const _hoisted_3 = { class: "carousel-indicators" }
const _hoisted_4 = ["data-bs-target", "data-bs-slide-to", "aria-current", "aria-label"]
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "carousel-inner" }
const _hoisted_7 = ["data-bs-target"]
const _hoisted_8 = ["data-bs-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["part-2-container", { reverse: _ctx.reverse }])
  }, [
    _createElementVNode("div", {
      id: _ctx.id,
      class: "carousel slide w-100 my-4"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.total, (i) => {
            return (_openBlock(), _createElementBlock("button", {
              type: "button",
              "data-bs-target": `#${_ctx.id}`,
              "data-bs-slide-to": i - 1,
              class: _normalizeClass({ active: i === 1 }),
              "aria-current": i === 1,
              "aria-label": `Slide ${i}`
            }, null, 10, _hoisted_4))
          }), 256)),
          _createElementVNode("div", null, [
            _createElementVNode("span", null, _toDisplayString(`${_ctx.index + 1}`.padStart(2, '0')), 1),
            _createElementVNode("span", null, "/" + _toDisplayString(`${_ctx.total}`.padStart(2, '0')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _createElementVNode("button", {
        class: "carousel-control-prev",
        type: "button",
        "data-bs-target": `#${_ctx.id}`,
        "data-bs-slide": "prev"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: "/img/carousel-control-prev-icon.png",
          alt: "Previous",
          "aria-hidden": "true"
        }, null, -1),
        _createElementVNode("span", { class: "visually-hidden" }, "Previous", -1)
      ]), 8, _hoisted_7),
      _createElementVNode("button", {
        class: "carousel-control-next",
        type: "button",
        "data-bs-target": `#${_ctx.id}`,
        "data-bs-slide": "next"
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("img", {
          src: "/img/carousel-control-next-icon.png",
          alt: "Next",
          "aria-hidden": "true"
        }, null, -1),
        _createElementVNode("span", { class: "visually-hidden" }, "Next", -1)
      ]), 8, _hoisted_8)
    ], 8, _hoisted_1)
  ], 2))
}