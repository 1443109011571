import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "home-part" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row row-cols-1 row-cols-md-3 g-3" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "part-item" }
const _hoisted_6 = { class: "part-item-title" }
const _hoisted_7 = { class: "container-fluid" }
const _hoisted_8 = { class: "row row-cols-4 row-cols-md-2 g-3 gy-lg-5" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "part-item-content" }
const _hoisted_11 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartTitle = _resolveComponent("PartTitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PartTitle, { src: "/img/home/part-5/title.png" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (x) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("img", {
                          src: x.img.src,
                          alt: x.img.alt
                        }, null, 8, _hoisted_11),
                        _createElementVNode("label", null, _toDisplayString(x.label), 1)
                      ])
                    ]))
                  }), 256))
                ])
              ])
            ])
          ]))
        }), 256))
      ])
    ])
  ]))
}