import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "home-part" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = { class: "row row-cols-1 row-cols-md-2 g-3 g-lg-4" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "item" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "item-body" }
const _hoisted_8 = { class: "item-title" }
const _hoisted_9 = { class: "item-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartTitle = _resolveComponent("PartTitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PartTitle, { src: "/img/home/part-2/title.png" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                class: "item-img",
                src: item.src
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(item.content), 1)
              ])
            ])
          ]))
        }), 256))
      ])
    ])
  ]))
}