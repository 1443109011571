import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/banner-control-left.png'
import _imports_1 from '@/assets/img/banner-control-right.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  id: "navbar-1-collapse",
  class: "collapse navbar-collapse"
}
const _hoisted_3 = { class: "navbar-nav" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = {
  class: "offcanvas offcanvas-end",
  tabindex: "-1",
  id: "offcanvasNavbar",
  "aria-labelledby": "offcanvasNavbarLabel"
}
const _hoisted_6 = { class: "offcanvas-header" }
const _hoisted_7 = {
  class: "offcanvas-title",
  id: "offcanvasNavbarLabel"
}
const _hoisted_8 = { class: "offcanvas-body" }
const _hoisted_9 = { class: "navbar-nav justify-content-end flex-grow-1 pe-3" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 0,
  class: "carousel-indicators"
}
const _hoisted_13 = ["data-bs-slide-to"]
const _hoisted_14 = { class: "carousel-inner" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 1,
  class: "carousel-control-prev",
  type: "button",
  "data-bs-target": "#carousel-app-1",
  "data-bs-slide": "prev"
}
const _hoisted_19 = {
  key: 2,
  class: "carousel-control-next",
  type: "button",
  "data-bs-target": "#carousel-app-1",
  "data-bs-slide": "next"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoSvg = _resolveComponent("LogoSvg")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_ContactUs = _resolveComponent("ContactUs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      class: _normalizeClass(["navbar-1 navbar navbar-expand-md", { scroll: !_ctx.banners.length }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_RouterLink, {
          to: "/",
          class: "navbar-brand"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_LogoSvg)
          ]),
          _: 1
        }),
        _cache[0] || (_cache[0] = _createElementVNode("button", {
          class: "navbar-toggler",
          type: "button",
          "data-bs-toggle": "offcanvas",
          "data-bs-target": "#offcanvasNavbar"
        }, [
          _createElementVNode("span", { class: "navbar-toggler-icon" })
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (item) => {
              return (_openBlock(), _createElementBlock("li", _hoisted_4, [
                _createVNode(_component_RouterLink, {
                  to: item.to,
                  class: _normalizeClass([item.class ?? '', "nav-link"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.text), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "class"])
              ]))
            }), 256))
          ])
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_LogoSvg, { style: {"color":"#E97448"} })
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("button", {
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "offcanvas",
          "aria-label": "Close"
        }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("ul", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (item) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_10, [
              _createElementVNode("a", {
                href: item.to,
                class: _normalizeClass([item.class ?? '', "nav-link"])
              }, _toDisplayString(item.text), 11, _hoisted_11)
            ]))
          }), 256))
        ])
      ])
    ]),
    (_ctx.banners && !!_ctx.banners.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "carousel-app-1",
          class: _normalizeClass(["carousel slide", { 'zindex--1': _ctx.banners.length === 1 }]),
          "data-bs-ride": "carousel"
        }, [
          (_ctx.banners.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.banners, (item, i) => {
                  return (_openBlock(), _createElementBlock("button", {
                    type: "button",
                    "data-bs-target": "#carousel-app-1",
                    "data-bs-slide-to": i,
                    class: _normalizeClass({ active: item.active })
                  }, null, 10, _hoisted_13))
                }), 256))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.banners, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["carousel-item", { active: item.active }])
              }, [
                _createElementVNode("img", {
                  src: item.src,
                  class: "d-block w-100"
                }, null, 8, _hoisted_15),
                (item.content)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("div", {
                        innerHTML: item.content,
                        class: "carousel-caption"
                      }, null, 8, _hoisted_17)
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 256))
          ]),
          (_ctx.banners.length > 1)
            ? (_openBlock(), _createElementBlock("button", _hoisted_18, _cache[2] || (_cache[2] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "prev",
                  class: "img-fluid"
                }, null, -1),
                _createElementVNode("span", { class: "visually-hidden" }, "Previous", -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.banners.length > 1)
            ? (_openBlock(), _createElementBlock("button", _hoisted_19, _cache[3] || (_cache[3] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "next",
                  class: "img-fluid"
                }, null, -1),
                _createElementVNode("span", { class: "visually-hidden" }, "Next", -1)
              ])))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_RouterView),
    (_ctx.contactUs)
      ? (_openBlock(), _createBlock(_component_ContactUs, {
          key: 1,
          class: "mt-5"
        }))
      : _createCommentVNode("", true)
  ], 64))
}