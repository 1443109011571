import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-part" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row row-cols-2 row-cols-md-3 row-cols-lg-4 g-3" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title" }, "部分合作单位", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "desc" }, "* 展示排名不分先后", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("img", {
              src: item.src,
              alt: item.alt,
              class: "img-fluid"
            }, null, 8, _hoisted_5)
          ]))
        }), 256))
      ])
    ])
  ]))
}