import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/img/home/part-1/_Img0001.png'


const _hoisted_1 = { class: "part-item py-5" }
const _hoisted_2 = { class: "part-item-bottom flex" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "part-item-top" }, [
      _createElementVNode("div", { class: "text" }, [
        _createElementVNode("h3", null, "什么是定向运动"),
        _createElementVNode("div", { class: "desc" }, "定向运动就是利用地图和指北针依次到达地图上所示的各个地点，以最短时间到达所有地点者为胜。定向运动通常城市公园、校园、景区、营地和社区里进行。形式也是多种多样的，包含国际标准定向赛、趣味定向赛、亲子定向赛、团建定向赛、教育定向赛、文化定向赛....只有你想不到的没有办不到的!而且参与的人群非常广泛，从3岁孩童到80岁老人都可参与。")
      ]),
      _createElementVNode("img", { src: _imports_0 })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: "items"
        }, [
          _createElementVNode("img", {
            src: item.imgs[0].src
          }, null, 8, _hoisted_3),
          _createElementVNode("div", null, [
            _createElementVNode("h4", null, _toDisplayString(item.bodies[0].title), 1),
            _createElementVNode("div", null, _toDisplayString(item.bodies[0].content), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}