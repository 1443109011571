import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "home-part" }
const _hoisted_2 = { class: "part-item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "part-item-body" }
const _hoisted_5 = { class: "part-item-title" }
const _hoisted_6 = { class: "part-item-subtitle" }
const _hoisted_7 = { class: "part-item-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartCarousel2 = _resolveComponent("PartCarousel2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PartCarousel2, {
      id: `home-part-3-carousel-2`,
      reverse: true
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([{ active: index === 0 }, "carousel-item"])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: item.img,
                class: "part-item-img"
              }, null, 8, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(item.subtitle), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(item.content), 1)
              ])
            ])
          ], 2))
        }), 256))
      ]),
      _: 1
    })
  ]))
}