import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "home-part" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row row-cols-1 row-cols-md-2 g-3" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "item" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "item-body" }
const _hoisted_8 = { class: "item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartTitle = _resolveComponent("PartTitle")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PartTitle, { src: "/img/home/part-6/title.png" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: item.src,
                class: "item-img"
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1),
                _createVNode(_component_RouterLink, {
                  to: item.href,
                  class: "item-btn"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("了解更多")
                  ])),
                  _: 2
                }, 1032, ["to"])
              ])
            ])
          ]))
        }), 256))
      ])
    ])
  ]))
}